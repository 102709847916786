<template>
    <OverlayWidget v-if="show">
        <template #header>
            <h3>Ampel Job auswählen</h3>
        </template>
        
            <div v-for="job of aktiveJobs" @click="() => onYes(job.vorgangsId)" class="job">
                {{ job.jobName }}
            </div>
        
        <template #footer>
            <ButtonWidget text="Abbrechen" iconName="close" @click="onNo" />
        </template>
    </OverlayWidget>
</template>

<style lang="scss">
    .job {
        padding: 0.25rem 0.5rem;
        border: 1px solid black;
        margin-bottom: 0.5rem;
        cursor: pointer;
    }
</style>

<script setup lang="ts">

    import { ref } from 'vue';
    import { ampelService } from '@/services/ampelService'
    import ButtonWidget from "../widgets/button-widget.vue";
    import OverlayWidget from "../widgets/overlay-widget.vue";

    const show = ref(false);
    const resolve = ref();
    const aktiveJobs = ref([]);

    const onYes = function(jobId) {
        resolve.value?.(jobId);
        show.value = false;
    };

    const onNo = function() {
        resolve.value?.(null);
        show.value = false;
    };

    const showModal = async function () {
        return new Promise(async (res, rej) => {
            resolve.value = res;
            show.value = true;
            aktiveJobs.value = await ampelService
                .listeErfassungen({
                    aktiveJobs: true, 
                    beendeteJobs: false
                })
                .then(r => r?.vorgaenge ?? []);
        });
    }

    defineExpose({
        showModal,
    });

</script>