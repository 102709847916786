import { fetchHelper } from "./fetchHelper.ts";

export interface GetAmazonAccountsResponse {
    items: GetAmazonAccountsResponseItem[]
}
export interface GetAmazonAccountsResponseItem {
    accountName: string,
    sellerId: string,
    isDefault: boolean
}

export const configService = {
    getAmazonAccounts: () : Promise<GetAmazonAccountsResponse> => {
        return fetchHelper('config/amazonAccounts', {});
    }
};