<template>
    <OverlayWidget v-if="show">
        <template #header>
            <h3>Ampel Job auswählen</h3>
        </template>
            
        <div class="form-group">
            <label>Bestellnummer Shopware</label>
            <input type="text" autofocus
                v-model="sucheEingabe"  
                v-on:keyup.enter="onYes" />    
            <strong class="importantnote" v-if="sucheEingabe && !canYes">
                Keine Referenz vorhanden
            </strong>   
        </div>   
        
        <template #footer>
            <ButtonWidget text="Abbrechen" iconName="close" @click="onNo" />
            <ButtonWidget text="Weiter" iconName="check" @click="onYes" :disabled="!canYes"/>
        </template>
    </OverlayWidget>
</template>

<script setup lang="ts">

    import { ref, computed, Ref } from 'vue';
    import { type AmpelErfassungListeQueryResultItem, ampelService } from '@/services/ampelService'
    import ButtonWidget from "../widgets/button-widget.vue";
    import OverlayWidget from "../widgets/overlay-widget.vue";
    import { isEmpty } from "lodash";

    const show = ref(false);
    const resolve = ref();
    const aktiveJobs : Ref<AmpelErfassungListeQueryResultItem[]>= ref([]);
    const sucheEingabe : Ref<string>  = ref();

    const canYes = computed(() => {
        return (vorgangZuEingabe.value != null);
    })

    const onYes = function() {
        if (canYes.value === true) {
            let vorgangId = vorgangZuEingabe.value?.vorgangsId;
            resolve.value?.(vorgangId);
            show.value = false;
        }
    };

    const onNo = function() {
        resolve.value?.();
        show.value = false;
    };

    const showModal = async function () {
        sucheEingabe.value = "";
        return new Promise(async (res, rej) => {
            resolve.value = res;
            show.value = true;
            aktiveJobs.value = await ampelService
                .listeErfassungen({
                    aktiveJobs: true, 
                    beendeteJobs: false
                })
                .then(r => r?.vorgaenge ?? []);
        });
    }

    const vorgangZuEingabe = computed(() => {
        const b = sucheEingabe.value;
        if (isEmpty(b)) 
            return null;
        return aktiveJobs.value?.find(v => (v.jagerBestellNummer == b));
    })

    defineExpose({
        showModal,
    });

</script>