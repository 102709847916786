import { RouteRecordRedirect, createRouter, createWebHashHistory } from "vue-router";

import HomeView from "@/components/views/home/home-view.vue";
import ConfigView from "@/components/views/config/config-view.vue";
import NeuView from "@/components/views/neu/neu-view.vue";
import ListeView from "@/components/views/liste/liste-view.vue";
import ReaktivierenView from "@/components/views/reaktivieren/reaktivieren-view.vue";
import BearbeitenView from "@/components/views/bearbeiten/bearbeiten-view.vue";
import ErfassungView from "@/components/views/erfassung/erfassung-view.vue";
import HistorieView from "@/components/views/historie/historie-view.vue";
import AbschlussView from "@/components/views/abschluss/abschluss-view.vue";
import UmlabelnView from "@/components/views/umlabeln/umlabeln-view.vue";

const routes = [
    {
        path: '/home',
        component: HomeView
    },
    {
        path: '/config',
        component: ConfigView
    },    
    {
        path: '/neu',
        component: NeuView
    },
    {
        path: '/liste',
        component: ListeView
    },
    {
        path: '/reaktivieren',
        component: ReaktivierenView
    },
    {
        path: '/bearbeiten/:vorgangsId',
        component: BearbeitenView,
        props: true
    },    
    {
        path: '/erfassung/:vorgangsId',
        component: ErfassungView,
        props: true
    },
    {
        path: '/historie/:vorgangsId',
        component: HistorieView,
        props: true
    },
    {
        path: '/abschluss/:vorgangsId',
        component: AbschlussView,
        props: true
    },
    {
        path: '/umlabeln',
        component: UmlabelnView
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/home'
    } as RouteRecordRedirect
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
});

import { credentialsService } from '@/services/credentialsService';

router.beforeEach(function (to, from, next) {
    let isLoggedIn = credentialsService.isLoggendIn();
    if (!isLoggedIn && to.path !== '/home') {
        next({ path: '/home' });
    } else {
        next();
    }
});