<template>
    <BaseView>
        <template v-slot:header>
            <h1>Erfassung reaktivieren</h1>
        </template>     
        <div class="liste">
            <template v-if="items && items.length > 0">
                <div class="item" v-for="item in items" :key="item.vorgangsId" @click="() => reactivateJob(item)">
                    <h3>{{ item.jobName }}</h3>
                    <label>Anlagedatum:</label>
                    <div>{{ dateFormat(item.createdAt) }}</div>
                    <label>Beendigungsdatum:</label>
                    <div>{{ dateFormat(item.finishedAt) }}</div>
                </div>
            </template>
            <template v-else>
                <strong>keine offene Aufträge gefunden</strong>
            </template>
        </div>
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/>
        </template>
        <AskModal ref="ask-modal" />
    </BaseView>
</template>

<style lang="scss">
    .liste {
        .item {
            padding: 6px;
            margin: 6px;
            border: 1px solid #4284F4;
            background-color: rgba(0,0,0,0.05);
            color: #4284F4;
            cursor: pointer;
            h3 {
                text-align: left;
                font-size: 24px;
                margin: 0 0 4px 0;
            }
        }
    }
</style>

<script>

    import { ampelService } from '@/services/ampelService'
    import { orderBy } from 'lodash';
    import { dateFormat } from "@/util/filter.js"
    
    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import AskModal from "./reaktivieren-view-ask-modal.vue";

    export default {
        components: {
            BaseView,
            ButtonWidget,
            AskModal
        },
        data: function() {
            return {
                items: []
            }
        },
        methods: {
            dateFormat,
            fetchData: async function() {
                let listeErfassungenResult = await ampelService.listeErfassungen({aktiveJobs: false, beendeteJobs: true})
                this.items = orderBy((listeErfassungenResult?.vorgaenge || []), "createdAt", "desc");                
            },
            reactivateJob: async function(item) {
                let choice = await this.$refs['ask-modal'].showModal(item);
                if (choice == true) {
                    await ampelService
                        .reaktiviereErfassung({
                            vorgangsId: item.vorgangsId
                        });
                    await this.fetchData();
                }                        
            }
        }, 
        mounted: function() {
            this.fetchData();
        }
    }
</script>