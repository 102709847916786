<script setup lang="ts">
    
    import { ref } from "vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import OverlayWidget from "../../widgets/overlay-widget.vue";

    const item = ref();
    const show = ref(false);
    let resolvePromise = null;

    const onYes = async () => {
        if (resolvePromise)
            resolvePromise(true);
        show.value = false;    
    }

    const onNo = async () => {
        if (resolvePromise) 
            resolvePromise(false);
        show.value = false;
    }

    const showModal = async (job) => {
        return new Promise((resolve, reject) => {
            resolvePromise = resolve;           
            item.value = job;        
            show.value = true;
        });
    }

    defineExpose({
        showModal
    });

</script>

<template>
    <OverlayWidget v-if="show">
        <template #header>
            <h3>Ampeljob reaktivieren</h3>
        </template>
        <p>Soll der Ampeljob `{{item != null ? item.jobName : ''}}` reaktiviert werden?</p>
        <template #footer>
            <ButtonWidget text="Ja" v-focus="true" iconName="check" @click="onYes" />
            <ButtonWidget text="Nein" iconName="close" @click="onNo" />
        </template>
    </OverlayWidget>
</template>
