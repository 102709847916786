import { ampelLog } from "./ampelLog";

import axios from "axios"; 

export const axiosClient = axios.create();

export const fetchHelper = async (method: string, payload: object) => {

    const path = `api/v2/ampel/${method}`;

    ampelLog.push({
        level: 'TRACE',
        path: path
    });
    
    return axiosClient
        .post(path, payload)
        .then(response => response.data)
        .catch(error => {
            ampelLog.push({
                level: 'ERROR',
                path: path,
                error
            });
        });

};