<script setup lang="ts">

    import { defineAsyncComponent } from 'vue'

    const props = defineProps<{
        iconName: 'add'
                    | 'check'
                    | 'close'
                    | 'delete'
                    | 'list'
                    | 'search'
                    | 'home'
                    | 'settings'
                    | 'arrow_up'
                    | 'arrow_right'
                    | 'arrow_down'
                    | 'arrow_left'
                    | 'person'
                    | 'group'
                    | 'vpn_key'
                    | 'print'
                    | 'filter_list'
                    | 'first_page'
                    | 'last_page'
                    | 'sort'
                    | 'save'
                    | 'all_inbox'
                    | 'done'
                    | 'info'
                    | 'file_download'
                    | 'file_upload'
                    | 'barcode'
                    | 'pencil'
    }>()

    const Icon = defineAsyncComponent(() => import(`../../assets/icons/${props.iconName}.svg`))

</script>

<template>
    <Icon @click="$emit('click', $event)" class="icon-widget" />
</template>
