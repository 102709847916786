<script setup lang="ts">

    import { ampelService } from '@/services/ampelService';
    import { credentialsService } from '@/services/credentialsService';
    import { ampelLog } from "@/services/ampelLog";
    import { numericFormat } from '@/util/filter';
    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import IconWidget from "../../widgets/icon-widget.vue";
    import AskModal from "./erfassung-view-ask-zustand-modal.vue";

    import { ZebraClient, Printer } from "@/clients/zebra/zebra-client";
    import fnSkuTemplate from "@/clients/zebra/labelTemplateFnSku.zpl?raw";
    import { computed, ref, useTemplateRef, watch } from 'vue';
    
    const zebraClient = new ZebraClient();
    const availablePrinter = ref();

    const props = defineProps<{
        vorgangsId: string
    }>();

    const id_input = useTemplateRef('id_input');
    const askZustandModal = useTemplateRef('ask-zustand-modal');

    const versandOption = ref();
    const interneReferenz = ref();
    const queryType = ref();
    const aktuellerKarton = ref();
    const aktuelleId = ref();
    const aktuelleAnzahl = ref();
    const aktuellerKartonLeer = ref(true);
    const abfrageErgebnis = ref();
    const abfrageAktiv = ref(false);
    const fbaInboundPrintLabel = ref(false);
    const identifierType = ref();

    const canQueryData = computed(() => {
        if (!aktuellerKarton.value)
            return false;
        if (!aktuelleId.value)
            return false;
        if (!aktuelleAnzahl.value)
            return false;
        return true;
    })

    const canKartonSchliessen = computed(() => {
        return !aktuellerKartonLeer.value;
    })

    const queryTypeText = computed(() => {
        switch (queryType.value) {
            case 'USED': 
                return "gebr";
            case 'NEW': 
                return "neu";
            default: 
                return "???";
        }
    })

    /**
     * Dieses Feld soll grundsätzlich angezeigt werden,
     * es sein denn die Rolle JAGER ist gesetzte ohne JAGER_ADMIN
     */
    const showAnzahl = computed(() => {
        let hasJager = credentialsService.hasAmpelJagerPrivilege();
        if (!hasJager) return true;
        let hasJagerAdmin = credentialsService.hasAmpelJagerAdminPrivilege();
        if (hasJagerAdmin) return true;
        return false;
    })

    const init = async function() {
        await ampelService
            .leseKartonStatistik({
                vorgangsId: props.vorgangsId
            })
            .then(result => {
                versandOption.value = result.vorgangsVersandOption;
                interneReferenz.value = result.interneReferenz;
                queryType.value = result.queryType;
                identifierType.value = result.identifierType;
                fbaInboundPrintLabel.value = result.fbaInboundPrintLabel;
                let kartonNummern = result.kartons.map(x => x.kartonNummer);
                if (kartonNummern.length > 0) {
                    aktuellerKartonLeer.value = false;
                    aktuellerKarton.value = Math.max(...kartonNummern);
                } else {
                    aktuellerKartonLeer.value = true;
                    aktuellerKarton.value = 1;
                }
            });
        aktuelleId.value = '';
        aktuelleAnzahl.value = 1;                    
        id_input.value.focus();
    }

    const queryData = async function() {
        if (canQueryData.value == false) {
            id_input.value.focus();
            return;
        } else {
            abfrageErgebnis.value = null;
            abfrageAktiv.value = true;
            let skipQuery = false;

            // #214
            // Wenn die Eingabe weniger als 10 Stellen oder genau 11 oder genau 12 Stellen hat,
            // dann EAN und ISBN mit 2x auffüllen um 13 Stellen zu erreichen 
            const identifierTypeValue = identifierType.value;
            if ((identifierTypeValue == 'EAN') || (identifierTypeValue == 'ISBN')) {
                const aktuelleIdLength = aktuelleId.value?.length ?? 0;
                if ((aktuelleIdLength < 10) || (aktuelleIdLength == 11) || (aktuelleIdLength == 12)) {
                    aktuelleId.value = aktuelleId.value.padStart(13, "2000000000000");
                }
            }

            if (queryType.value == 'USED')  {
                await ampelService
                    .vorverarbeiteEingabe({
                        vorgangsId: props.vorgangsId,
                        id: aktuelleId.value
                    })
                    .then(async result => { 
                        if (result.vlbFixedPrice === false) {
                            await askZustandModal.value
                                .showModal()
                                .then(choice => {
                                    skipQuery = !choice; 
                                })
                        }
                    });
            }
            if (skipQuery === false) {
                await ampelService
                    .verarbeiteEingabe({
                        vorgangsId: props.vorgangsId,
                        id: aktuelleId.value,
                        anzahl: aktuelleAnzahl.value,
                        kartonNummer: aktuellerKarton.value
                    })
                    .then(result => {
                        if (result?.errorMessage) {
                            ampelLog.push({
                                level: 'ERROR',
                                path: `api/v2/ampel/verarbeiteEingabe`,
                                error: {
                                    status: 500, 
                                    statusText: result.errorMessage 
                                }
                            });
                        } 
                        abfrageErgebnis.value = result;
                        if (result?.anzahlKlasseGruen > 0)
                            aktuellerKartonLeer.value = false;
                    });
                if (fbaInboundPrintLabel.value) {
                    if (abfrageErgebnis.value?.anzahlKlasseGruen > 0) {
                        let fnSku = abfrageErgebnis.value?.extItemId2;
                        let numLabels = abfrageErgebnis.value?.anzahlKlasseGruen;
                        await printLabel(fnSku, numLabels);
                    }
                }
            }
            abfrageAktiv.value = false;
            aktuelleId.value = '';
            aktuelleAnzahl.value = 1;
            id_input.value.focus();
        }
    }
    
    const printLabel = async function(fnSku, numLabels) {
                
        // Parameter prüfen
        if (!fnSku) {
            alert("FNSKU konnte nicht ermittelt werden. Artikel zur Seite legen!");
            return;
        }

        // Drucker suchen (evtl. gecached)
        if (!Array.isArray(availablePrinter.value)) {
            console.log("Drucker nicht gecached ...")
            try {
                console.log("Suche Drucker ...")
                availablePrinter.value = await zebraClient.getAvailablePrinter();
                console.log("Suche Drucker abgeschloßen", availablePrinter.value)
            }
            catch (e) {
                alert("Fehler beim Suchen der Drucker.");
                console.error(e);
                availablePrinter.value = null;
            }
        }

        // Ersten Drucker wählen
        const selectedPrinter  = availablePrinter.value?.[0];
        console.log("Drucker ausgewählt aus Cache", selectedPrinter)
        if (!selectedPrinter) {
            alert("Drucker nicht gefunden.")
            return;
        }

        // Drucken
        try {
            const zplDokument = fnSkuTemplate.replaceAll("{{FNSKU}}", fnSku);
            for (let i=0; i<numLabels; i++) {
                await zebraClient.write(selectedPrinter, zplDokument);
            }
        } catch (e) {
            alert("Fehler beim Drucken. Abbruch.")
            console.error(e);
            return;                    
        }

    }

    const kartonSchliessen = function() {
        if (!canKartonSchliessen.value) 
            return;
        let x = Math.max(1, aktuellerKarton.value);
        aktuellerKarton.value = x + 1;
        aktuellerKartonLeer.value = true;
        abfrageErgebnis.value = null;
        aktuelleId.value = '';
        aktuelleAnzahl.value = 1;
        id_input.value.focus();
    }

    watch(
        () => props.vorgangsId, 
        () => { init(); }, 
        { immediate: true}
    );

</script>

<template>
    <BaseView>
        <template v-slot:header>
            <div class="header" @click="$router.push(`/bearbeiten/${vorgangsId}`)">
                <div class="left">
                    <svg width="42" height="34">
                        <rect x="1" y="0"  width="40" height="10" :style="{ fill: (versandOption && versandOption.indexOf('G') >= 0) ? 'rgb(0,128,0)'   : 'rgb(128,128,128)' }" />
                        <rect x="1" y="12" width="40" height="10" :style="{ fill: (versandOption && versandOption.indexOf('Y') >= 0) ? 'rgb(255,255,0)' : 'rgb(128,128,128)' }" />
                        <rect x="1" y="24" width="40" height="10" :style="{ fill: (versandOption && versandOption.indexOf('R') >= 0) ? 'rgb(255,0,0)'   : 'rgb(128,128,128)' }" />
                    </svg>
                    <div>{{queryTypeText}}</div>
                </div>
                <div class="center">
                    <h1>Daten erfassen zu {{interneReferenz}}</h1>
                </div>
                <div class="right">
                    <div>
                        <IconWidget iconName="all_inbox" />
                        {{aktuellerKarton}}
                    </div>
                </div>
            </div>
        </template>   
        <div class="body">
            <div class="form-group">
                <label>ID:</label>
                <input type="text"  
                    ref="id_input"
                    v-model="aktuelleId" 
                    v-on:keyup.enter="queryData" />       
            </div>  
            <div class="form-group" v-if="showAnzahl">
                <label>Anzahl:</label>
                <input type="number" 
                    v-model="aktuelleAnzahl"
                    v-on:keyup.enter="queryData" />       
            </div>
            <div class="form-group" v-if="abfrageAktiv">
                <h3>Abfrage läuft ...</h3>
            </div>
            <div class="form-group" v-if="!abfrageAktiv && abfrageErgebnis">
                <label>Ergebnis der letzten Abfrage:</label>
                <div v-if="abfrageErgebnis.anzahlKlasseNichtGefunden == 0">
                    <div>
                        ASIN: <span>{{abfrageErgebnis.ergebnisAsin}}</span>
                    </div>
                    <div>
                        Titel: <span>{{abfrageErgebnis.ergebnisTitel}}</span>
                    </div>
                    <div>
                        max. EK: <span>{{ numericFormat(abfrageErgebnis.ergebnisMaxEk, {numDecimalPlaces: 2, showThousandSeperator: true})}}</span>
                    </div>
                    <div>
                        Rang: <span>{{ numericFormat(abfrageErgebnis.ergebnisRang, {numDecimalPlaces: 0, showThousandSeperator: true})}}</span>
                    </div>
                    <div class="ampel">
                        <div class="green" v-if="abfrageErgebnis.anzahlKlasseGruen > 0">
                            <div>{{abfrageErgebnis.anzahlKlasseGruen}}</div>
                        </div>
                        <div class="yellow" v-if="abfrageErgebnis.anzahlKlasseGelb > 0">
                            <div>{{abfrageErgebnis.anzahlKlasseGelb}}</div>
                        </div>
                        <div class="red" v-if="abfrageErgebnis.anzahlKlasseRot > 0">
                            <div>{{abfrageErgebnis.anzahlKlasseRot}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h2>keine Daten gefunden</h2>
                </div>
            </div>
        </div>  
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/> 
            <ButtonWidget 
                iconName="all_inbox" 
                text="Kartoninhalt anzeigen" 
                @click="$router.push('/historie/' + vorgangsId)" /> 
            <ButtonWidget 
                iconName="all_inbox" 
                :text="'Karton ' + aktuellerKarton + ' schließen'" 
                @click="kartonSchliessen"
                :disabled="!canKartonSchliessen" />   
            <ButtonWidget 
                iconName="arrow_right" 
                text="Kompletten Auftrag beenden" 
                @click="$router.push('/abschluss/' + vorgangsId)" />               
        </template>
        
        <AskModal 
            ref="ask-zustand-modal" />
            
    </BaseView>
</template>

<style lang="scss">
    div.header {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto 1fr;
        cursor: pointer;
        > .left {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: start;
            align-items: center;
            > svg {
                align-self: center; 
                margin: 4px;
            }
        }
        > .center {
            display: grid;
        }
        > .right {
            display: grid;
            justify-content: end;
            > div {
                align-self: center; 
                text-align: center;
                display: grid;
                align-content: center;
                margin: 4px;
                width: 48px;
                height: 48px;
                position: relative;
                font-size: 36px;
                font-weight: bold;
                z-index: 0;
                > svg {
                    position: absolute;
                    left: 4px;
                    top: 4px;
                    width: 40px;
                    height: 40px;
                    fill: rgba(255,255,255,0.5);
                    z-index: -1;
                }
            }
        }
    }
    div.ampel {
        display: flex;
        > div {
            flex: 1 1 auto;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;    
            color: white;
            font-size: 48px;        
        }
        .red {
            background-color: red;
        }
        .yellow {
            background-color: yellow;
        }
        .green {
            background-color: green;
        }
    }
</style>