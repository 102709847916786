/**
 * Formatiert einen Datumswert von ISO (YYYYMMDD'T'HHmmSS) nach DD.MM.YYYY HH:mm:SS
 * 
 * @param {String} value 
 * @returns 
 */
export function dateFormat(value) {
    if (!value) return ''
    if (value.length < 14) return ''
    let formated = value.substr(6,2) + "." + 
        value.substr(4,2) + "." + 
        value.substr(0,4) + " " +
        value.substr(9,2) + ":" +
        value.substr(11,2) + ":" + 
        value.substr(13,2);
    return formated;
}

/**
 * 
 * @param {Number} value 
 * @param {Object} args
 * @param {Number} args.numDecimalPlaces
 * @param {Boolean} args.showThousandSeperator
 * @returns 
 */
export function numericFormat(value, args) {

    if (!value) return ''
    if (isNaN(value)) return '';

    let numDecimalPlaces = (args?.numDecimalPlaces && Number.isInteger(args.numDecimalPlaces)) ? args.numDecimalPlaces : 0;
    let currentFormated = value.toFixed(numDecimalPlaces);
    if (numDecimalPlaces > 0)
        currentFormated = currentFormated.replace(".", ",");

    let showThousandSeperator = Boolean(args?.showThousandSeperator);
    if (showThousandSeperator)
        currentFormated = currentFormated.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return currentFormated;

}
