import axios from "axios"; 

export class Printer {
    deviceType;
    uid;
    provider;
    name;
    connection;
    version;
    manufacturer;
}

/**
 * Zebra Client für Browser Print Application
 */
export class ZebraClient {
    
    constructor() {
        this._axios = axios.create({baseURL: "http://localhost:9100"});
    }

    /**
    * liefert die Liste der verfügbaren Drucken
    * @returns {Promise<Printer[]>} 
    */
    async getAvailablePrinter() {
        return this._axios
            .get('available')
            .then(response => {
                return response?.data
                    ?.printer
                    ?.map(p => {
                        Object.setPrototypeOf(p, Printer.prototype);
                        return p;
                    })
                    ?? [];
            });
    }

    /**
     * sendet `data` an den den `printer`
     * @param {Printer} printer 
     * @param {string} data 
     * @returns {Promise<void>}
     */
    async write(printer, data) {
        return this._axios
            .post('write', {
                data, 
                device: printer
            });
    }
    
};