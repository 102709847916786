<template>
    <BaseView>
        <template v-slot:header>
            <h1>Konfiguration</h1>
        </template> 

        <div class="list">
            <div v-for="g in groupedItems" :key="g.listenName" class="item">
                <div class="name" @click="() => g.collapsed = !g.collapsed">
                    <template v-if="g.collapsed">
                        <IconWidget iconName="arrow_down" />
                    </template>
                    <template v-else>
                        <IconWidget iconName="arrow_up" />
                    </template>
                    {{ g.listenName }}
                </div>                
                <div class="actions">
                    <ButtonWidget 
                        iconName="delete" 
                        text="diese Liste löschen" 
                        @click="() => onDeleteConfig(g.listenName)"/>
                </div>
                <div class="table" v-if="!g.collapsed">
                    <!-- Header -->
                    <div class="cell header">Query</div>
                    <div class="cell header">Type</div>
                    <div class="cell header">ID</div>
                    <div class="cell header">Anzahl grün</div>
                    <div class="cell header">Anzahl gelb</div>
                    <div class="cell header">Anzahl rot</div>
                    <div class="cell header">Benutzt grün</div>
                    <div class="cell header">Benutzt gelb</div>
                    <div class="cell header">Benutzt rot</div>
                    <!-- Body -->
                    <template v-for="item in g.items" :key="g.listenName + item.queryType + item.identifierType + item.itemId">
                        <div class="cell">{{item.queryType}}</div>
                        <div class="cell">{{item.identifierType}}</div>
                        <div class="cell">{{item.itemId}}</div>
                        <div class="cell">{{item.anzahlGruen}}</div>
                        <div class="cell">{{item.anzahlGelb}}</div>
                        <div class="cell">{{item.anzahlRot}}</div>
                        <div class="cell">{{item.benutztGruen}}</div>
                        <div class="cell">{{item.benutztGelb}}</div>
                        <div class="cell">{{item.benutztRot}}</div>
                    </template>
                </div>
            </div>
        </div>

        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')" />            
            <ButtonWidget 
                iconName="add" 
                text="neue Liste hochladen" 
                @click="onUploadFile"/>
            <ConfigFileUpload ref="configFileUpload"/>
            <ButtonWidget 
                iconName="file_download" 
                text="Beispieldatei" 
                @click="onDowloadSample" />
        </template>
    </BaseView>
</template>

<style lang="scss">
    
    @import "@/resources/global.scss";
    
    div.list {
        > div.item {
            display: grid;
            grid-template-columns: 1fr auto;            
            > div.name {
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                font-weight: bold;
                font-size: 28px;
                margin: 4px;
                padding: 4px;
                cursor: pointer;
                > svg.icon-widget {
                    width: 32px;
                    height: 32px;
                }
            }
            > div.table {
                grid-column: 1 / -1;
                display: grid;
                align-self: start;
                grid-template-columns: repeat(9, auto);
                margin: 4px;
                padding: 4px;
                border: 1px solid;
                > div.cell {
                    height: 24px;
                    display: block;
                    &.header {
                        font-weight: bold;
                    }
                    &:nth-child(18n+1), &:nth-child(18n+2), &:nth-child(18n+3), &:nth-child(18n+4), &:nth-child(18n+5), &:nth-child(18n+6), &:nth-child(18n+7), &:nth-child(18n+8), &:nth-child(18n+9) {
                        background: $color-primary-1;
                    }
                    &:nth-child(18n+10), &:nth-child(18n+11), &:nth-child(18n+12), &:nth-child(18n+13), &:nth-child(18n+14), &:nth-child(18n+15), &:nth-child(18n+16), &:nth-child(18n+17),&:nth-child(18n+18) {
                        background: $color-primary-2;
                    }
                }
            }
        }
    }


</style>

<script setup lang="ts">

    import { type AmpelBacklistKonfiguration, type AmpelBacklistKonfigurationItem, backlistService } from '@/services/backlistService'
    import { ampelLog } from '@/services/ampelLog'
    import { sortBy } from 'lodash';

    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import IconWidget from "../../widgets/icon-widget.vue";
    import ConfigFileUpload from "./config-file-upload.vue";
    import { ComputedRef, Ref, computed, onMounted, ref } from 'vue';

    interface DisplayElement extends AmpelBacklistKonfiguration {
        collapsed: boolean
        items: AmpelBacklistKonfigurationItem[]
    }

    const configFileUpload : Ref<InstanceType<typeof ConfigFileUpload> | null> = ref(null)
    const groupedItems : Ref<DisplayElement[]> = ref();
    onMounted(async () => {
        await loadConfigs();
    })

    const loadConfigs = async()=> {
        const config = await backlistService.leseKonfiguration(); 
        groupedItems.value = config?.map(groupedItem => {
            return { ...groupedItem, 
                collapsed: true, 
                items: sortBy(groupedItem.items, ['queryType', 'identifierType', 'itemId'])
            }
        }) ?? [];
    }

    const onDowloadSample = () => {
        location.href = 'ampel.backlist.sample.csv';
    };

    const onDeleteConfig = async (listenName) => {
        await backlistService.loescheKonfiguration({ listenName });   
        await loadConfigs();
    }

    const onUploadFile = async () => {    
        try {
            await configFileUpload.value?.triggerUpload();
            await loadConfigs();
        } catch (err) {
            ampelLog.push(err);
        }
    }

</script>