<template>
    <BaseView>
        <template v-slot:header>
            <h1>Erfassung beginnen</h1>
        </template>     
        <div class="body">
            <div class="form-group" v-if="isNotJager">
                <label>ID Typ:</label>
                <select v-model="selectedIdType">
                    <option v-for="option of idTypeList" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
                <strong class="importantnote" v-if="selectedIdType == 'MFSA'">- Ist die Mappingtabelle befüllt? -</strong>   
            </div>        
            <div class="form-group">
                <label>Typ:</label>
                <select v-model="selectedQueryType">
                    <option v-for="option of queryTypeList" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>          
            <div class="form-group">
                <label>interne Referenz:</label>
                <input type="text" autofocus
                    v-model="interneReferenzRegEx"  
                    v-on:keyup.enter="next" />    
            </div> 
            <div class="form-group" v-if="isNotJager">
                <label>Bestellnummer Shopware:</label>
                <input type="text" autofocus
                    v-model="jagerBestellNummer"  
                    v-on:keyup.enter="next" />    
            </div> 
            <div style="display: grid; grid-template-columns: minmax(0, 1fr) auto;" v-if="isNotJager">
                <div class="form-group">
                    <label>Aufteilung:</label>
                    <select v-model="selectedBacklistName">
                        <option v-for="option of backlistList" :value="option.name" :key="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>              
                <div>
                    <ConfigFileUpload ref="configFileUpload"/>
                    <ButtonWidget iconName="add" 
                        :iconOnly="true"
                        text="neue Liste hochladen" 
                        @click="uploadNewBacklist"/>
                </div>
            </div>
            <div class="form-group">
                <label>Versandoption:</label>
                <select v-model="selectedVersandOption">
                    <option v-for="option in versandOptionList" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>  
            <div class="form-group" v-if="isNotJager">
                <label>Daten bei Amazon anlegen</label>
                <select v-model="fbaInbound">
                    <option :value="true">Ja</option>
                    <option :value="false">Nein</option>
                </select>                
            </div>   
            <div class="form-group" v-if="isNotJager">
                <label>FNSKU Labels drucken</label>
                <select v-model="fbaInboundPrintLabel" :disabled="fbaInbound == false">
                    <option :value="true">Ja</option>
                    <option :value="false">Nein</option>
                </select>                
            </div>               
            <div class="form-group">
                <label>Amazonkonto:</label>
                <select v-model="amazonAccount" :disabled="fbaInbound == false">
                    <option v-for="option in amazonAccounts" :value="option.sellerId" :key="option.accountName">
                        {{ option.accountName }} 
                    </option>
                </select>
            </div>    
        </div>
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/>
            <ButtonWidget 
                iconName="arrow_right" 
                text="Erfassung beginnen"  
                @click="next" 
                :disabled="!canNext"/>
        </template>
    </BaseView>
</template>

<script setup lang="ts">

    import { credentialsService } from '@/services/credentialsService';
    import { type GetAmazonAccountsResponseItem, configService } from '@/services/configService'
    import { ampelService } from '@/services/ampelService'
    import { backlistService } from '@/services/backlistService'
    import { queryTypeList, idTypeList, versandOptionList } from "./constants"

    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import ConfigFileUpload from "../config/config-file-upload.vue";
    import { Ref, computed, onMounted, ref, watchEffect } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    // Template Ref
    const configFileUpload : Ref<InstanceType<typeof ConfigFileUpload> | undefined> = ref();

    // Wertelisten
    const amazonAccounts : Ref<GetAmazonAccountsResponseItem[]> = ref([]);
    const backlistList : Ref<{id: string, name: string}[]> = ref([]);

    // Eingaben
    const selectedIdType = ref(idTypeList[2].value);
    const selectedQueryType = ref(null);
    const interneReferenz = ref('')
    const jagerBestellNummer = ref('');
    const selectedBacklistName = ref(null);
    const selectedVersandOption = ref(null);
    const fbaInbound = ref(true);
    const fbaInboundPrintLabel = ref(null);
    const amazonAccount = ref(null);

    // Eingabehelper
    const interneReferenzRegEx = computed({
        get: function () {
            return interneReferenz.value;
        },
        set: function (newValue) {
            const valueToWrite = newValue.match('[a-zA-Z0-9\-_ \.]*');  
            if (valueToWrite && valueToWrite.length > 0) {
                interneReferenz.value = '';
                interneReferenz.value = valueToWrite[0];
            }                   
        }
    })

    // Änderung fbaInbound
    watchEffect(() => {
        if (fbaInbound.value === true) {
            amazonAccount.value = null;
            fbaInboundPrintLabel.value = true;
            selectedVersandOption.value = credentialsService.hasAmpelJagerPrivilege() 
                ? versandOptionList[2].value
                : versandOptionList[0].value;
        } else {    
            amazonAccount.value = null;
            fbaInboundPrintLabel.value = null;
            selectedVersandOption.value = versandOptionList[2].value;
        }
    });

    // Berechtigungen
    const isNotJager = computed(() => {
        return !credentialsService.hasAmpelJagerPrivilege();
    });

    // Weiter
    const canNext = computed(() => {
        if (!interneReferenz.value) return false;
        if (!selectedQueryType.value) return false;
        if (!selectedIdType.value) return false;
        if (!selectedVersandOption.value) return false;
        if (fbaInbound.value) {
            if (!amazonAccount.value) return false;
        }
        return true;
    });
    const next = async function() {
                
        if (canNext.value === false) 
            return;

        let cmd = {
            identifierType: selectedIdType.value,
            queryType: selectedQueryType.value,
            interneReferenz: interneReferenz.value,
            backlistName: selectedBacklistName.value,
            versandOption: selectedVersandOption.value,
            fbaInbound: fbaInbound.value,
            fbaInboundKartonFeed: true,
            fbaInboundPrintLabel: fbaInboundPrintLabel.value,
            fbaInboundKonto: amazonAccount.value,
            jagerBestellNummer: jagerBestellNummer.value
        };

        ampelService
            .starteErfassung(cmd)
            .then(cmdResult => {
                console.log(cmdResult);
                router.push(`/erfassung/${cmdResult.vorgangsId}`);
            });

    }

    // Backlist Upload
    const uploadNewBacklist = async function() {
        configFileUpload.value
            ?.triggerUpload()
            ?.then(listenName => {
                backlistList.value = [...backlistList.value, { id: listenName, name: listenName }];
                selectedBacklistName.value = listenName;
            });
    }

    // Laden
    onMounted(async () => {
        backlistList.value = await backlistService
            .abfrage()
            .then(queryResult => queryResult?.items?.map(row => ({
                    id: row.id,
                    name: row.name
                })
            ));
        amazonAccounts.value = await configService
                .getAmazonAccounts()
                .then(queryResult => {
                    return queryResult?.items;
                });
    })

</script>
