<template>
    <BaseView>
        <template v-slot:header>
            <h1>Auftrag abschließen</h1>
        </template>     
        <div class="body">
            <div class="form-group" v-if="!abschlussErgebnis">
                <h3>Abfrage läuft ...</h3>
            </div>
            <div class="form-group" v-else>
                <h3>Zusammenfassung</h3>
                <div style="padding: 2rem; padding-bottom: 0; display: grid; column-gap: 1rem; row-gap: 0.25rem; grid-template-columns: 1fr; justify-items: center;">
                    <b>Anzahl Kartons {{anzahlKartons}}</b>
                </div>
                <div style="padding: 2rem; padding-bottom: 0; display: grid; column-gap: 1rem; row-gap: 0.25rem; grid-template-columns: 1fr 1fr 1fr; justify-items: center;">
                    <div style="display: contents">
                        <div style="font-weight: bold;">Klasse</div>
                        <div style="font-weight: bold;">Anzahl Exemplare</div>
                        <div style="font-weight: bold;">Anzahl Titel</div>
                    </div>
                    <div style="display: contents">
                        <div>grün</div>
                        <div>{{abschlussErgebnis.anzahlKlasseGruen}}</div>
                        <div>{{abschlussErgebnis.anzahlTitelGruen}}</div>
                    </div>
                    <div style="display: contents">
                        <div>gelb</div>
                        <div>{{abschlussErgebnis.anzahlKlasseGelb}}</div>
                        <div>{{abschlussErgebnis.anzahlTitelGelb}}</div>
                    </div>
                    <div style="display: contents">
                        <div>rot</div>
                        <div>{{abschlussErgebnis.anzahlKlasseRot}}</div>
                        <div>{{abschlussErgebnis.anzahlTitelRot}}</div>
                    </div>
                    <div style="display: contents">
                        <div>nicht gefunden</div>
                        <div>{{abschlussErgebnis.anzahlKlasseNichtGefunden}}</div>
                        <div>{{abschlussErgebnis.anzahlTitelNichtGefunden}}</div>
                    </div>
                </div>
            </div>            
        </div>
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/>
        </template>
    </BaseView>
</template>

<style lang="scss">
    table {
        td, th {
            text-align: center;
            width: 50%;
        }
    }
</style>

<script setup lang="ts">

    import { type AmpelErfassungAbschliessenCommandResult, ampelService } from '@/services/ampelService'

    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import { Ref, onMounted, ref } from 'vue';

    const props = defineProps<{
        vorgangsId: string
    }>();

    const abschlussErgebnis : Ref<AmpelErfassungAbschliessenCommandResult> = ref();
    const anzahlKartons = ref();
      
    const fetchData = async () => {
        abschlussErgebnis.value = await ampelService
            .beendeErfassung({
                vorgangsId: props.vorgangsId
            });

        await ampelService
            .leseKartonStatistik({
                vorgangsId: props.vorgangsId
            })
            .then(result => {
                let kartonNummern = result.kartons.map(x => x.kartonNummer);
                anzahlKartons.value = kartonNummern.length > 0 ? Math.max(...kartonNummern) : 1;
            });
    }
    
    onMounted(async() => {
        await fetchData();
    })

</script>