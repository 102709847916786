import { fetchHelper } from "./fetchHelper.ts";

export interface AmpelBacklistKonfiguration {
    listenName: string
    items: AmpelBacklistKonfigurationItem[]
}
export interface AmpelBacklistKonfigurationItem {
    queryType: any
    identifierType: any
    itemId: string
    anzahlGruen: number
    anzahlGelb: number
    anzahlRot: number
    benutztGruen: number
    benutztGelb: number
    benutztRot: number
}
export interface AmpelBacklistLoeschenCommand {
    listenName: string
}
export interface AmpelBacklistAbfrageResult {
    items: AmpelBacklistAbfrageResultItem[]
}
export interface AmpelBacklistAbfrageResultItem {
    id: string
    name: string
}

export const backlistService = {
    leseKonfiguration: () : Promise<AmpelBacklistKonfiguration[]> => {
        return fetchHelper('backlist/leseKonfiguration', {}); 
    },
    setzeKonfiguration: (params: AmpelBacklistKonfiguration) => {
        return fetchHelper('backlist/setzeKonfiguration', params); 
    },
    loescheKonfiguration: (params: AmpelBacklistLoeschenCommand) => {
        return fetchHelper('backlist/loescheKonfiguration', params); 
    },
    abfrage: () : Promise<AmpelBacklistAbfrageResult> =>  {
        return fetchHelper('backlist/abfrage', {}); 
    }
};