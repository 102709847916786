<template>
    <BaseView>
        <template v-slot:header>
            <h1>Erfassung fortsetzen</h1>
        </template>     
        <div class="liste">
            <template v-if="items && items.length > 0">
                <div v-for="item in items" :key="item.vorgangsId" class="item" @click="$router.push(`erfassung/${item.vorgangsId}`)">
                    <h3>{{ item.jobName }}</h3>
                    <div class="row">
                        <div>
                            <label>Anlagedatum:</label>
                            <div>{{ dateFormat(item.createdAt) }}</div>
                        </div>
                        <div>
                            <label>Aufteilung:</label>
                            <div>{{ item.backlistName || "-"}}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <strong>keine offene Aufträge gefunden</strong>
            </template>
        </div>
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/>
        </template>
    </BaseView>
</template>

<style lang="scss">
    .liste {
        .item {
            padding: 6px;
            margin: 6px;
            border: 1px solid #4284F4;
            background-color: rgba(0,0,0,0.05);
            color: #4284F4;
            cursor: pointer;
            h3 {
                text-align: left;
                font-size: 24px;
                margin: 0 0 4px 0;
            }
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }
</style>

<script>

    import { ampelService } from '@/services/ampelService'
    import { dateFormat } from "@/util/filter.js"
    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";

    export default {
        components: {
            BaseView,
            ButtonWidget
        },
        data: function() {
            return {
                items: []
            }
        },
        methods: {
            dateFormat,
            fetchData: async function() {
                ampelService
                    .listeErfassungen({aktiveJobs: true, beendeteJobs: false})
                    .then(e => this.items = e.vorgaenge);
            }
        }, 
        mounted: function() {
            this.fetchData();
        }
    }
</script>