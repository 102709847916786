export const idTypeList = [
    {value: "EAN",      text: "EAN"},
    {value: "ASIN",     text: "ASIN"},
    {value: "ISBN",     text: "ISBN"}, 
    {value: "MFSA",     text: "FNSKU Mapping"}
]

export const versandOptionList = [
    {value: "G",            text: "grün"},
    {value: "GY",           text: "grün+gelb"},
    {value: "GYR",          text: "grün+gelb+rot"}
];

export const queryTypeList = [
    {value: "USED", text: "gebraucht"},
    {value: "NEW",  text: "neu"}
];