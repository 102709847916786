<template>
    <OverlayWidget v-if="show">
        <template #header>
            <h3>Mängeln nicht erforderlich</h3>
        </template>
        <p>Für diesen Artikel wurde die Ladenpreisbindung aufgehoben, das Mängeln ist daher nicht erforderlich.</p>
        <p>Sollte der Artikel nicht neuwertig sein, bitte 'OK' wählen und einpacken.</p>
        <p>Sollte der Artikel neuwertig sein, bitte 'Zustand neu' wählen und später in separatem Auftrag erfassen.</p>
        <template #footer>
            <ButtonWidget text="OK" v-focus="true" iconName="check" @click="onYes" />
            <ButtonWidget text="Zustand neu" iconName="close" @click="onNo" />
        </template>
    </OverlayWidget>
</template>

<script>

    import ButtonWidget from "../../widgets/button-widget.vue";
    import OverlayWidget from "../../widgets/overlay-widget.vue";

    export default {
        components: {
            ButtonWidget,
            OverlayWidget
        }, 
        data: function() {
            return {
                show: false,
                resolve: null
            }
        },
        methods: {
            onYes: function() {
                if (this.resolve)
                    this.resolve(true);
                this.show = false;
            },
            onNo: function() {
                if (this.resolve)
                    this.resolve(false);
                this.show = false;
            }, 
            async showModal() {
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.show = true;
                });
            }
        }
    }
</script>