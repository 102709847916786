<template>
    <BaseView>
        <template v-slot:header>
            <h1>Einstieg</h1>
        </template>  

        <template v-slot:footer>
            <ButtonWidget v-if="!isJager"
                iconName="add" 
                text="Erfassung beginnen" 
                @click="$router.push('/neu')"/>
            <ButtonWidget v-if="!isJager"
                iconName="pencil" 
                text="Erfassung bearbeiten" 
                @click="erfassungBearbeiten" />
            <ButtonWidget v-if="!isJager"
                iconName="barcode" 
                text="FNSKU Umlabeln" 
                @click="$router.push('/umlabeln')" />    
            <ButtonWidget v-if="!isJager"
                iconName="search" 
                text="Erfassung fortsetzen" 
                @click="$router.push('/liste')" />
            <ButtonWidget 
                iconName="search" 
                text="Wareneingang" 
                @click="erfassungStartenReferenz" />                   
            <ButtonWidget 
                iconName="search" 
                text="FBA Amazon Auftrag" 
                @click="erfassungStartenJager" />
            <ButtonWidget 
                iconName="search" 
                text="Amazon Paket direkt" 
                @click="erfassungStartenReferenz" />                    
            <ButtonWidget v-if="canAmpelConfig"
                iconName="settings" 
                text="Einstellungen" 
                @click="$router.push('/config')"/>
            <ButtonWidget v-if="canAmpelConfig"
                iconName="settings" 
                text="Erfassung reaktivieren" 
                @click="$router.push('/reaktivieren')"/>                
        </template>
        
        <SelectAmpelJobDialog 
            ref="refSelectAmpelJobDialog" />
        <SelectAmpelJobJagerDialog
            ref="refSelectAmpelJobJagerDialog" />
        <SelectAmpelJobReferenzDialog 
            ref="refSelectAmpelJobReferenzDialog" />
    </BaseView>
</template>

<script setup lang="ts">

    import { credentialsService } from '@/services/credentialsService';

    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import SelectAmpelJobDialog from "../../dialogs/select-ampeljob-dialog.vue";
    import SelectAmpelJobJagerDialog from "../../dialogs/select-ampeljob-jager-dialog.vue";
    import SelectAmpelJobReferenzDialog from "../../dialogs/select-ampeljob-referenz-dialog.vue";
    import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const refSelectAmpelJobDialog = ref();
    const refSelectAmpelJobJagerDialog = ref();
    const refSelectAmpelJobReferenzDialog = ref();

    const canAmpelConfig = ref(false);
    const isJager = ref(false);

    onMounted(() => {
        canAmpelConfig.value = credentialsService.hasAmpelConfigPrivilege();
        isJager.value = credentialsService.hasAmpelJagerPrivilege();
    });

    const erfassungBearbeiten = async () => {
        let selectAmpelJobId = await refSelectAmpelJobDialog.value?.showModal();
        if (selectAmpelJobId) {
            router.push(`/bearbeiten/${selectAmpelJobId}`);
        }
    };

    const erfassungStartenJager = async () => {
        let selectAmpelJobId = await refSelectAmpelJobJagerDialog.value?.showModal();
        if (selectAmpelJobId) {
            router.push(`/erfassung/${selectAmpelJobId}`);
        }
    }

    const erfassungStartenReferenz = async () => {
        let selectAmpelJobId = await refSelectAmpelJobReferenzDialog.value?.showModal();
        if (selectAmpelJobId) {
            router.push(`/erfassung/${selectAmpelJobId}`);
        }
    }
</script>