<template>
    <BaseView>
        <template v-slot:header>
            <h1>Umlabeln von FNSKU nach EAN</h1>
        </template>     
        <div class="body">
            <div class="form-group">
                <label>
                    Drucker auswählen: 
                </label>
                <div style="display: grid; grid-template-columns: 1fr auto; gap: 4px;">
                    <select v-model="selectedPrinter">
                        <option v-for="option in availablePrinter" :value="option" :key="option.uid">
                            {{ option.name }}
                        </option>
                    </select>
                    <ButtonWidget 
                        style="margin: 0;"
                        iconName="print" 
                        text="Testdruck" 
                        :disabled="selectedPrinter == null"
                        @click="onTestDruck"/>
                </div>
            </div>    
            <div class="form-group">
                <label for="mappingDatei" style="display: flex; justify-content: space-between;">
                    <span>Datei </span> 
                    <small>
                        <a href="ampel.umlabeln.FNSKU_Mapping_Beispiel.xlsx">
                            Beispieldatei
                        </a>
                    </small>
                </label>
                <input type="file" id="mappingDatei" 
                    ref="uploadInput" 
                    @change="onUploadFileChange" />
                <template v-if="scanFromFileItems.length > 0">
                    <strong>{{scanFromFileItems.length}} Zeilen geladen.</strong>
                </template>
            </div>  
            <div class="form-group">
                <label>ID:</label>
                <input type="text"  
                    ref="id_input"
                    v-model="aktuelleFnSku" 
                    v-on:keyup.enter="onQueryData" />       
            </div> 

            <div class="result--green" v-if="mappingErfolg">
                <span>{{ mappingErfolg }}</span>
            </div>                       
            <div class="result--red" v-if="mappingFehler">
                <span>{{ mappingFehler }}</span>
            </div>                       

        </div>
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zum Einstieg" 
                @click="$router.push('/home')"/>
        </template>
    </BaseView>
</template>

<style lang="scss">
    .result {
        font-family: monospace;
        font-size: 1.5rem;
        margin: 1rem;
        padding: 1rem;
        border: 1px solid black;
        color: black;
        &--green {
            @extend .result;
            background-color: green;
        }
        &--red {
            @extend .result;
            background-color: red
        }
    }
</style>

<script>

    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";

    import { ZebraClient, Printer } from "@/clients/zebra/zebra-client";
    import ean13Template from "@/clients/zebra/labelTemplate.zpl?raw";
    const zebraClient = new ZebraClient();

    import { read, utils } from "xlsx";

    async function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        })
    }

    async function readExcelFirstSheetAsync(uploadInputElement, { columnNamesToUpper = true } = {}) {
        const selectedFile = uploadInputElement.files[0];
        const contentBuffer = await readFileAsync(selectedFile);
        const xlsxWorkbook = read(contentBuffer);
        const firstWorksheet = xlsxWorkbook.Sheets[xlsxWorkbook.SheetNames[0]];

        if (columnNamesToUpper) {
            if (firstWorksheet["!ref"]) {
                let range = utils.decode_range(firstWorksheet["!ref"]);
                for(let C = range.s.c; C <= range.e.c; ++C) {
                    let address = utils.encode_col(C) + "1"; // <-- first row, column number C
                    if(!firstWorksheet[address]) continue;
                    firstWorksheet[address].w = firstWorksheet[address].w.toUpperCase();
                }
            }
        }

        return {
            name: selectedFile.name,
            content: utils.sheet_to_json(firstWorksheet, {
                blankrows: false,
                raw: true
            })
        };
    }

    export default {
        components: {
            BaseView,
            ButtonWidget
        },
        data: function() {
            return {
                /** @type {Printer[]} */
                availablePrinter: [],
                /** @type {Printer|null} */
                selectedPrinter: null,
                /** @type {{fnsku: string, asin: string, ean: string}[]} */
                scanFromFileItems: [],
                /** @type {string|null} */
                aktuelleFnSku: null,
                /** @type {string|null} */
                mappingErfolg: null,
                /** @type {string|null} */
                mappingFehler: null,
            }
        },
        computed: {
            
        }, 
        methods: {
            async updateAvailablePrinterList() {
                this.availablePrinter = await zebraClient.getAvailablePrinter();
                if (this.selectedPrinter == null) {
                    this.selectedPrinter = this.availablePrinter?.[0];
                }
            },
            async onTestDruck() {
                await this.onPrintLabelForEan("9783593385433");
                this.$refs.id_input.focus();
            },
            async onPrintLabelForEan(ean) {
                const zplDokument = ean13Template.replaceAll("{{EAN}}", ean);
                if (this.selectedPrinter != null) 
                    await zebraClient.write(this.selectedPrinter, zplDokument);
            },
            async onUploadFileChange() {
                const p = await readExcelFirstSheetAsync(this.$refs['uploadInput']);
                this.scanFromFileItems = p.content.map(x => ({
                    fnsku: x['FNSKU'],
                    asin: x['ASIN'],
                    ean: x['EAN'],
                }));
                this.$refs.id_input.focus();
            },
            async onQueryData() {
                if (this.aktuelleFnSku != null && this.aktuelleFnSku.length > 0) {
                    let mappedResult = this.scanFromFileItems
                        ?.find(x => x?.fnsku?.toLowerCase() == this.aktuelleFnSku?.toLowerCase());
                    if (mappedResult?.ean) {
                        this.mappingErfolg = `ASIN: ${mappedResult.asin} EAN: ${mappedResult.ean}`;
                        this.mappingFehler = null;
                        await this.onPrintLabelForEan(mappedResult.ean);
                    } else {
                        this.mappingErfolg = null;
                        this.mappingFehler = `FNSKU: ${this.aktuelleFnSku} Mapping fehlt`;
                    }
                }

                this.aktuelleFnSku = "";
                this.$refs.id_input.focus();
            }
        },
        mounted: async function() {
            await this.updateAvailablePrinterList();
            this.$refs.id_input.focus();
        }
    }

</script>