<template>
    <div class="button-widget" :class="{ disabled: disabled, 'icon-only': iconOnly }" 
        tabindex="0" 
        @click="onClick" @keyup="onKeyup"
        v-tooltip="text"
    >
        <div class="icon">
            <IconWidget v-if="iconName" :iconName="iconName" />
        </div>
        <div class="text">
            {{ text }}
        </div>
    </div>
</template>

<style lang="scss">
    @import "@/resources/global.scss";
    .button-widget {
        display: grid;
        grid-auto-flow: row;
        justify-items: center;
        align-items: center;
        min-width: 128px;
        width: auto;
        max-width: 192px;
        > .icon {
            height: 48px;
            width: 48px;
            svg {
                height: 100%;
                width: 100%;
                fill: #4284F4;
            }
        }
        > .text {
            font-size: 14px;
            max-width: 100%;
            @include textEllipsisOnOverflow();
        }
        padding: 6px;
        margin: 4px;
        border: 1px solid #4284F4;
        background-color: white;
        color: #4284F4;
        cursor: pointer;
        &.disabled {
            color: lightgray;
            border: 1px solid lightgray;
            cursor: not-allowed;
            > .icon {
                svg { 
                    fill: lightgray;
                }
            }
        }
        &.icon-only {
            width: 64px;
            height: 64px;
            .text {
                display: none;
            }
        }
    }
</style>

<script>

    import IconWidget from "./icon-widget.vue";

    export default {
        components: {
            IconWidget
        }, 
        emits: [
            'click'
        ],
        props: {
            iconName: {
                type: String,
                required: true
            },
            text: {
                type: String,
                required: true
            }, 
            iconOnly: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        }, methods: {
            onClick: function() {
                if (!this.disabled) 
                    this.$emit('click');
            },
            onKeyup: function(evt) {
                if (evt.keyCode === 13) {
                    this.onClick();
                }
            }
        }
    }
</script>