<template>
    <BaseView>
        <template v-slot:header>
            <h1>Inhalt Karton {{ aktuellerKarton }}</h1>
        </template>     
        <div class="historien-liste" v-if="kartonScans">
            <template v-if="kartonScans && kartonScans.length > 0">
                <div class="item" style="font-weight: bold;">
                    <div>ID</div>
                    <div>Datum</div>
                    <div>Gr</div>
                    <div>Ge</div>
                    <div>Ro</div>
                    <div>X</div>
                </div>
                <div class="item" v-for="item in kartonScans" :key="item.id">
                    <div>{{ item.scanId }}</div>
                    <div>{{ dateFormat(item.createdAt) }}</div>
                    <div>{{ item.anzahlKlasseGruen }}</div>
                    <div>{{ item.anzahlKlasseGelb }}</div>
                    <div>{{ item.anzahlKlasseRot }}</div>
                    <div>{{ item.anzahlKlasseNichtGefunden }}</div>
                </div>
            </template>
            <template v-else>
                <strong>keine Einträge für den aktuellen Karton gefunden</strong>
            </template>
        </div>
        <template v-slot:footer>
            <ButtonWidget 
                iconName="arrow_left" 
                text="zurück zur Erfassung" 
                @click="$router.push('/erfassung/' + vorgangsId)" />
            <ButtonWidget 
                iconName="delete" 
                text="letzte Erfassung löschen" 
                @click="deleteLetztenScan" 
                :disabled="!canDelete" />
            <ButtonWidget 
                iconName="delete" 
                text="aktuellen Karton löschen" 
                @click="deleteGanzenKarton"
                :disabled="!canDelete" />
            <ButtonWidget 
                iconName="delete" 
                text="ganzen Auftrag löschen" 
                @click="deleteGanzenAuftrag"
                :disabled="!canDelete" />
        </template>
        <OverlayWidget v-if="loescheScanAktiv || loescheKartonAktiv || loescheAuftragAktiv">
            <template v-slot:header>
                <h3 >Nachfrage</h3>
            </template>
            <template v-if="loescheScanAktiv">
                Soll die letzte Erfassung wirklich gelöscht werden?
            </template>
            <template v-if="loescheKartonAktiv">
                Soll der gesamte Karton wirklich gelöscht werden?
            </template>
            <template v-if="loescheAuftragAktiv">
                Soll der gesamte Auftrag wirklich gelöscht werden?
            </template>
            <ButtonWidget v-slot:footer text="Ja" iconName="check" @click="deleteYes" />
            <ButtonWidget v-slot:footer text="Nein" iconName="close" @click="deleteNo" />
        </OverlayWidget>
    </BaseView>
</template>

<style lang="scss">
    @import "@/resources/global.scss";
    .historien-liste {
        > .item {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1fr 1fr repeat(4, minmax(30px, 0.3fr));
            > div {
                padding: 4px;
            }
            > div:not(:first-child) {
                text-align: right;
            }
            &:nth-child(even) {
                background: $color-primary-1;
            }
            &:nth-child(odd) {
                background: $color-primary-2;
            }
        }
    }
</style>

<script>

    import { ampelService } from '@/services/ampelService'
    import { orderBy } from 'lodash'
    import { dateFormat } from "@/util/filter.js"

    import BaseView from "../base-view.vue";
    import ButtonWidget from "../../widgets/button-widget.vue";
    import OverlayWidget from "../../widgets/overlay-widget.vue";
    
    export default {
        components: {
            BaseView,
            ButtonWidget,
            OverlayWidget
        },
        props: {
            vorgangsId: {
                type: String,
                required: true
            }
        },
        data: function() {
            return {
                aktuellerKarton: null,
                kartonScans: [],
                loescheScanAktiv: false,
                loescheKartonAktiv: false,
                loescheAuftragAktiv: false,
            }
        },        
        computed: {
            canDelete: function() {
                return Array.isArray(this.kartonScans) && this.kartonScans.length > 0;
            }
        },
        methods: {
            dateFormat,
            fetchData: async function() {
                await ampelService
                    .leseKartonStatistik({
                        vorgangsId: this.vorgangsId
                    })
                    .then(result => {
                        let kartonNummern = result.kartons.map(x => x.kartonNummer);
                        this.aktuellerKarton = kartonNummern.length > 0 ? Math.max(...kartonNummern) : 1;
                    })
                await ampelService.eingabeHistorie({
                        vorgangsId: this.vorgangsId,
                        kartonNummer: this.aktuellerKarton
                    })
                    .then(result => {
                        let sortedItems = orderBy(result.items, ['createdAt'], ['desc']);
                        this.kartonScans = sortedItems;
                    })
            },
            deleteLetztenScan: function() {
                this.loescheScanAktiv = true;
            },
            deleteGanzenKarton: function() {
                this.loescheKartonAktiv = true;
            },
            deleteGanzenAuftrag: function() {
                this.loescheAuftragAktiv = true;
            },
            deleteNo: function() {
                this.loescheScanAktiv = false;
                this.loescheKartonAktiv = false;
                this.loescheAuftragAktiv = false;
            },
            deleteYes: async function() {
                let cmd = { vorgangsId: this.vorgangsId };
                if (this.loescheScanAktiv) 
                    cmd.itemId = this.kartonScans[0].id;
                if (this.loescheKartonAktiv) 
                    cmd.kartonNummer = this.aktuellerKarton;
                await ampelService
                    .loescheEingabe(cmd)
                    .then(result => {
                        this.deleteNo();
                        this.fetchData();
                    });    
            },
        },
        mounted: function() {
            this.fetchData(); 
        }
    }
</script>