<script setup lang="ts">
    
    import { computed } from 'vue';
    import { ampelLog } from "@/services/ampelLog";

    const errorMessages = computed(() => {
        let errorMessages = ampelLog
            ?._data
            ?.filter(msg => msg?.level == "ERROR")
            ?.map(msg => {
                return msg?.error?.response?.data?.message
                    ?? `${msg?.error?.status} ${msg?.error.statusText}`
            })
            ?? [];
        // remove duplicates
        return [...new Set(errorMessages)];
    });

    const hasErrorMessages = computed(() => {
        return errorMessages.value.length;
    });

    const onClose = () => {
        ampelLog.clear();
    }

</script>

<template>
    <div class="alert" v-if="hasErrorMessages">
        <span class="closebtn" @click="onClose">&times;</span>
        <ul>
            <li v-for="e of errorMessages">
                {{ e }}
            </li>
        </ul>
    </div>
</template>

<style lang="scss">

    .alert {
        padding: 1rem;
        background-color: rgb(220, 53, 69);
        border: 1px solid darkred;
        color: white;
        position: fixed;
        right: 1rem;
        top: 1rem;
        max-width: calc(100% - 2rem);
        ul {
            margin-left: 1rem;
        }
    }

    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    .closebtn:hover {
        color: black;
    }

</style>