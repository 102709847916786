import moment from 'moment';
import { reactive } from 'vue';


export interface LogEntry {
    level: 'TRACE' | 'INFO' | 'WARN' | 'ERROR',
    path: string,
    timeStamp ?: string,
    error ?: any
}

class AmpelLog {
    _data: LogEntry[]
    constructor() {
        this._data = [];
    }
    push (msg: LogEntry) {
        // ggf. TimeStamp hinzufügen
        if (msg.timeStamp == undefined)
            msg.timeStamp = moment().format("YYYYMMDD HHmmss");
        // ablegen
        this._data.push(msg);
    }

    clear() {
        while (this._data.length > 0) 
            this._data.pop();
    }
}

export const ampelLog = reactive(new AmpelLog());
